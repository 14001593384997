<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const nodes = ref()

onMounted(() => {
    get_nodes()
})

const get_nodes = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtigerv2/get-sync-status")
        .then(response => {
            console.log(response.data)
            nodes.value = response.data
            loader.value = false
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
}

const open_api_url = (url) => {
    window.open(process.env.VUE_APP_NEURAXIS_API_MAIN + url, '_blank')
}

</script>

<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Tree :value="nodes" class="w-full">
                <template #default="slotProps">
                    <span class="mr-2">{{ slotProps.node.label }}</span>
                    <Chip :label="String(slotProps.node.children.length)" />
                </template>
                <template #day="slotProps">
                    <span class="hover:font-bold cursor-pointer" @click="open_api_url('/flyingtigerv2/download-transaction-json?key='+slotProps.node.data)">{{ slotProps.node.label }}</span>
                </template>
            </Tree>
        </div>
    </BlockUI>
</template>