<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from 'primevue/useconfirm'
import Toast from 'primevue/toast'

import Purchase from './FlyingTiger-Purchase.vue'

const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const purchases = ref([])
const purchase = ref({})
const filter_purchases = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const newPurchaseDialog = ref(false)
const selected_xls_file = ref(null)
const selected_xls_file_name = ref(null)
const fileUpload = ref(null)


onMounted( async () => {
    get_purchases()
})

const get_purchases = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtiger/get-purchases')
    .then(response => {
        console.log(response.data)
        purchases.value = response.data
        loader.value = false
    })
}


const new_purchase = () => {
    newPurchaseDialog.value = true
}

const onSelectFile = (event) => {
    let reader = new FileReader()
    reader.readAsDataURL(event.target.files[0])
    reader.onload = () => {
        selected_xls_file.value = reader.result
    }
    selected_xls_file_name.value = event.target.files[0]
}

const save_new_purchase = async () => {
    loader.value = true
    let payload = {
        "name": purchase.value.name,
        "date": purchase.value.date,
        "file": selected_xls_file.value,
        "filename": selected_xls_file_name.value.name
    }
    await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/new-purchase", payload)
        .then(response => {
                purchases.value = response.data
                newPurchaseDialog.value = false
                toast.add({severity:'success', summary: 'Einkauf erstellt', detail: 'Der Einkauf wurde erfolgreich erstellt.', life: 3000});
        }).catch(error => {
        console.error("There was an error!", error.message);
    })
    loader.value = false
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const active_workflow = ref("inbox")
const workflow_tabs = ref([])
const toggle_active_workflow = (e) => {
    if (e == "inbox") {
        active_workflow.value = "inbox"
    }
    else {
        if(workflow_tabs.value.some(obj => obj.workflow_id == e)) {
            active_workflow.value = e
        }
        else {
            if (workflow_tabs.value.length == 0){
                active_workflow.value = "inbox"
            }
        }
    }
}
const close_tab = (workflow_id) => {
    for (let key in workflow_tabs.value){
        if (workflow_tabs.value[key]["workflow_id"] == workflow_id){
            workflow_tabs.value.splice(key, 1)
            console.log("workflow_tabs", workflow_tabs.value.length)
            if (workflow_tabs.value.length == 0) {
                active_workflow.value = "inbox"
            } else {
                active_workflow.value = workflow_tabs.value[workflow_tabs.value.length - 1]["workflow_id"]
            }
        }
    }
}
const open_workflow = (e) => {
    let workflow_id = e.data.id
    for (let key in purchases.value){
        if (purchases.value[key].id == workflow_id) {
            let found = workflow_tabs.value.some((el) => el.workflow_id === workflow_id);
            if (!found) {
                let name = ""
                if (purchases.value[key].workflow_id > 25) {
                    name = purchases.value[key].name.substring(0, 25) + "..."
                } else {
                    name = purchases.value[key].name
                }
                workflow_tabs.value.push({
                    "workflow_id": purchases.value[key].id,
                    "name": name
                })
                active_workflow.value = purchases.value[key].id
            }
            else
            {
                active_workflow.value = e.data.id
            }
        }
    }
}

const delete_purchase = (e) => {
    console.log("delete", e)
    confirm.require({
        message: 'Are you sure you want to delete this purchase?',
        header: 'Delete purchase?',
        rejectLabel: 'No',
        acceptLabel: 'Yes, delete it',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + "/flyingtiger/delete-purchase", e)
            .then(response => {
                purchases.value = response.data
                toast.add({severity:'success', summary: 'Purchase deleted', detail: 'The purchase was deleted successfully', life: 3000});
                loader.value = false
            })
        }
    })
}

const open_url = (url) => {
    window.open(url, '_blank')
}

</script>
<style>
.tab-primrary {
    background-color: #3751ff;
    color: #FFFFFF;
}
.tab-inactive {
    background-color: #0d2f3b;
    color: #FFFFFF;
}
.tab-hidden {
    display: none;
}
</style>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <Toast />
    <ConfirmDialog />
    <Toolbar>
        <template #start>
            <Button rounded label="Purchase" icon="pi pi-file-excel" v-tooltip.top="'Purchase documents'" @click="toggle_active_workflow('inbox')" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != 'inbox'}" class="mr-2" />
            <Button rounded v-for="(workflow_tab, key) in workflow_tabs" :key="key" @click="toggle_active_workflow(workflow_tab.workflow_id)" :class="{'tab-primary': active_workflow == 'inbox','tab-inactive': active_workflow != workflow_tab.workflow_id}" class="mr-2"><i class="pi pi-file mr-3"/>{{workflow_tab.name}}<i class="pi pi-times-circle ml-3 text-red-100" @click="close_tab(workflow_tab.workflow_id)" /></Button>
        </template>
    </Toolbar>
    <div v-if="active_workflow == 'inbox'">
        <Toolbar class="mt-2">
            <template #start>
                <span class="p-input-icon-left mr-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filter_purchases['global'].value" placeholder="Suche" class="w-full" />
                </span>
                <Button label="New purchase" @click="new_purchase()" class="w-auto mr-2" type="button" icon="pi pi-plus" v-tooltip.right="'Upload a new purchase'" />
            </template>
        </Toolbar>
        <DataTable @row-click="open_workflow" class="mt-3" v-model:filters="filter_purchases" :value="purchases" :rows="200" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[200,400,1000]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="date" header="Date" sortable>
                <template #body="slotProps">
                    {{ format_swiss_date(slotProps.data.date) }}
                </template>
            </Column>
            <Column field="name" header="Purchase description" />
            <Column field="filename" header="File">
                <template #body="slotProps">
                    <Chip :label="slotProps.data.filename" icon="pi pi-file-excel" class="mr-2" />
                    <Button class="p-button-rounded p-button-text p-button-danger" icon="pi pi-trash" @click="delete_purchase(slotProps.data)" />
                </template>
            </Column>
        </DataTable>
    </div>
    <div v-for="(workflow_tab, key) in workflow_tabs" :key="key" :hidden="active_workflow != workflow_tab.workflow_id">
        <Purchase :workflow_id="workflow_tab.workflow_id" />
    </div>
    <Dialog v-model:visible="newPurchaseDialog" :style="{width: '1200px'}" header="New purchase" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <InputText id="name" type="text" v-model="purchase.name" />
                    <label for="name">Name</label>
                </span>
            </div>
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="date" showIcon v-model="purchase.date" dateFormat="dd.mm.yy" />
                    <label for="date">Date</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <div class="flex align-content-center flex-wrap">
                    <input type="file" @change="onSelectFile" style="display: none" ref="fileUpload" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
                    <Button :label="selected_xls_file_name ? selected_xls_file_name.name : 'Upload XLSX File'" @click="fileUpload.click()" class="w-auto mr-4 mb-1" icon="pi pi-upload" />
                    <div class="text-primary font-bold cursor-pointer flex align-items-center justify-content-center"><span @click="open_url('https://cdn.snappy-pages.snpy.ch/flyingtiger/templates/purchase_template_flyingtiger_CH01.xlsx')"><i class="pi pi-file-excel mr-1" />Download excel template</span></div>
                </div>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="!selected_xls_file_name || !purchase.name || !purchase.date" label="Create new purchase" @click="save_new_purchase()" class="w-auto mr-2 mb-1" icon="pi pi-check" />
            </div>
        </div>
    </Dialog>
</template>