<script setup>
import { ref, onMounted, useSSRContext } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'
import { useConfirm } from "primevue/useconfirm"
import { EnumStringBody } from '@babel/types'
/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()
// filters
const filter_locations = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
const filter_statistics = ref({'global': {value: null, matchMode: FilterMatchMode.CONTAINS}})
//////////////////
// Data Sources //
//////////////////
const locations = ref([])
const location = ref({})
const statistics_date = ref()
const location_id = ref()
const statistics = ref()
const statistics_totals = ref({})

const edit_location_dialog = ref(false)

const location_statistics_date_dialog = ref(false)
const location_statistics_table = ref(false)

onMounted( async () => {
    get_locations()
})

const get_locations = () => {
    loader.value = true
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/get-locations')
    .then(response => {
        console.log(response.data)
        locations.value = response.data
        loader.value = false
    })
}

const edit_location = (event) => {
    location.value = event.data
    edit_location_dialog.value = true
}

const save_location = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/save-location', location.value)
    .then(response => {
        toast.add({ severity: 'success', summary: 'Location saved!', detail: 'Location ' + location.value.name + ' was saved', life: 3000})
        edit_location_dialog.value = false
        get_locations()
    })
}

const delete_location = () => {
    confirm.require({
        message: 'Are you sure you want to delete this location?',
        header: 'Delete location: ' + location.value.name + '?',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Yes, delete location',
        acceptClass: 'p-button-danger',
        rejectLabel: 'No',
        accept: () => {
            loader.value = true
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/delete-location', location.value)
            .then(response => {
                toast.add({ severity: 'success', summary: 'Location deleted!', detail: 'Location ' + location.value.name + ' was deleted', life: 3000})
                edit_location_dialog.value = false
                get_locations()
                loader.value = false
            })
        }
    })
}

const location_statistics_date = (event) => {
    location_id.value = event.data.id
    location_statistics_date_dialog.value = true
    console.log('location_statistics_date')
}

const location_statistics = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/flyingtigerv2/location-statistics', {
        location: location_id.value,
        date: statistics_date.value
    })
    .then(response => {
        statistics.value = response.data.export
        statistics_totals.value["cost_pre_total"] = response.data.cost_pre_total
        statistics_totals.value["cost_between_in_total"] = response.data.cost_between_in_total
        statistics_totals.value["cost_between_out_total"] = response.data.cost_between_out_total
        statistics_totals.value["cost_post_total"] = response.data.cost_post_total
        location_statistics_date_dialog.value = false
        location_statistics_table.value = true
        loader.value = false
    })
}

const open_url = (url) => {
    window.open(url, '_blank')
}

const format_swiss_date = (value) => {
    return value.substring(8,10) + '.' + value.substring(5,7) + '.' + value.substring(0,4)
}

const format_currency = (value) => {
    return Number(value).toLocaleString('de-CH', {style: 'currency', currency: 'CHF', minimumFractionDigits: 2});
}

const format_currency_without_prefix = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 2, maximumFractionDigits: 2})
}

const format_number = (value) => {
    return Number(value).toLocaleString('de-CH');
}

const format_number_with_rounding = (value) => {
    return Number(value).toLocaleString('de-CH', {minimumFractionDigits: 0});
}


</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>

<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <BlockUI :fullScreen="true" :blocked="loader">
        <div class="col-12 mb-2">
            <Toolbar>
                <template #start>
                    <span class="p-input-icon-left mr-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filter_locations['global'].value" placeholder="Search" style="width: 300px" />
                    </span>
                </template>
            </Toolbar>
        </div>
        <DataTable v-model:filters="filter_locations" :value="locations" :rows="50" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20,50,100]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column field="id" header="Location-ID" sortable style="width: 150px">
                <template #body="slotProps">
                    <Chip class="pl-0 pr-3 mr-2">
                        <span class="bg-primary bproduct-circle w-2rem h-2rem flex align-items-center justify-content-center"><i class="pi pi-map-marker" /></span>
                        <span class="ml-2 font-medium">{{slotProps.data.id}}</span>
                    </Chip>
                </template>
            </Column>
            <Column field="name" header="Location" sortable>
                <template #body="slotProps">
                    <span class="font-bold">{{slotProps.data.name}}</span>
                </template>
            </Column>
            <Column header="Status">
                <template #body="slotProps">
                    <span v-if="slotProps.data.status != 1" class="p-tag p-tag-success">Active</span>
                    <span v-else class="p-tag p-tag-danger">Inactive</span>
                </template>
            </Column>
            <Column style="width: 200px">
                <template #body="slotProps">
                    <Button icon="pi pi-chart-bar" class="p-button-rounded mr-2" @click="location_statistics_date(slotProps)" />
                    <Button icon="pi pi-pencil" class="p-button-rounded mr-2" @click="edit_location(slotProps)" />
                </template>
            </Column>
        </DataTable>
    </BlockUI>
    <Dialog v-model:visible="location_statistics_date_dialog" :style="{width: '1200px'}" header="Location Statistics" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-6 mt-3">
                <span class="p-float-label">
                    <Calendar id="date" view="month" dateFormat="mm.yy" showIcon v-model="statistics_date"  />
                    <label for="date">Month</label>
                </span>
            </div>
            <div class="field col-12 mt-3">
                <Button :disabled="!statistics_date" label="Generate stock statistics" @click="location_statistics()" class="w-auto mr-2 mb-1" icon="pi pi-chart-bar" />
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="edit_location_dialog" :style="{width: '60%'}" header="Location Statistics" :modal="true" class="p-fluid z-5">
        <div class="grid">
            <div class="col-6">
                <span class="p-float-label">
                    <InputText id="id" v-model="location.id" />
                    <Label for="id">ID</Label>
                </span>
            </div>
            <div class="col-6">
                <span class="p-float-label">
                    <InputText id="name" v-model="location.name" />
                    <Label for="name">Name</Label>
                </span>
            </div>
            <div class="col-8">
                <Button label="Save" @click="save_location()" class="w-auto p-button-success mr-2" icon="pi pi-save" />
                <Button label="Cancel" @click="edit_location_dialog = false" class="w-auto" icon="pi pi-times" />
            </div>
            <div class="col-4">
                <Button label="Delete location" class="p-button-danger p-button-text w-auto" icon="pi pi-trash" @click="delete_location()" />
            </div>
        </div>
    </Dialog>
    <Dialog v-model:visible="location_statistics_table" :style="{width: '80%'}" header="Location Statistics" :modal="true" class="p-fluid z-5">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-2">
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-2">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_statistics['global'].value" placeholder="Search" style="width: 300px" />
                        </span>
                    </template>
                </Toolbar>
            </div>
            <div class="field col-12 mt-3">
                <DataTable v-model:filters="filter_statistics" :value="statistics" :rows="50" responsiveLayout="scroll" :rowHover="true">
                    <Column field="item_no" header="No." sortable>
                        <template #body="slotProps">
                            <span class="font-bold">{{slotProps.data.item_no}}</span>
                        </template>
                    </Column>
                    <Column field="description" header="Description">
                        <template #body="slotProps">
                            <span>{{slotProps.data.description}}</span>
                        </template>
                    </Column>
                    <Column field="count_pre" header="Amount before">
                        <template #body="slotProps">
                            <span>{{format_number_with_rounding(slotProps.data.count_pre)}}</span>
                        </template>
                    </Column>
                    <Column field="cost_pre" header="Value before">
                        <template #body="slotProps">
                            <span>{{format_currency_without_prefix(slotProps.data.cost_pre)}}</span>
                        </template>
                    </Column>
                    <Column field="count_between_in" header="Amount increase">
                        <template #body="slotProps">
                            <span>{{format_number_with_rounding(slotProps.data.count_between_in)}}</span>
                        </template>
                    </Column>
                    <Column field="cost_between_in" header="Value increase">
                        <template #body="slotProps">
                            <span>{{format_currency_without_prefix(slotProps.data.cost_between_in)}}</span>
                        </template>
                    </Column>
                    <Column field="count_between_out" header="Amount decrease">
                        <template #body="slotProps">
                            <span>{{format_number_with_rounding(slotProps.data.count_between_out)}}</span>
                        </template>
                    </Column>
                    <Column field="cost_between_out" header="Cost decrease">
                        <template #body="slotProps">
                            <span>{{format_currency_without_prefix(slotProps.data.cost_between_out)}}</span>
                        </template>
                    </Column>
                    <Column field="count_post" header="Amount after">
                        <template #body="slotProps">
                            <span>{{format_number_with_rounding(slotProps.data.count_post)}}</span>
                        </template>
                    </Column>
                    <Column field="cost_post" header="Value after">
                        <template #body="slotProps">
                            <span>{{format_currency_without_prefix(slotProps.data.cost_post)}}</span>
                        </template>
                    </Column>
                    <ColumnGroup type="footer">
                        <Row>
                            <Column footer="Total:" :colspan="2" footerStyle="text-align:left" />
                            <Column :footer="format_currency(statistics_totals.cost_pre_total)" :colspan="2" footerStyle="text-align:right" />
                            <Column :footer="format_currency(statistics_totals.cost_between_in_total)" :colspan="2" footerStyle="text-align:right" />
                            <Column :footer="format_currency(statistics_totals.cost_between_out_total)" :colspan="2" footerStyle="text-align:right" />
                            <Column :footer="format_currency(statistics_totals.cost_post_total)" :colspan="2" footerStyle="text-align:right" />
                        </Row>
                    </ColumnGroup>
                </DataTable>
            </div>
        </div>
    </Dialog>
</template>